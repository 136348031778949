<template>
  <div v-if="userData">
    <v-navigation-drawer v-model="drawer" app class="elevation-20">
      <div class="d-flex justify-center pa-0 my-4 my-sm-6 my-md-8">
        <v-img
          v-if="$vuetify.theme.dark"
          class="d-inline-block"
          src="@/assets/GAMEX LOGO FINAL 3-02.png"
          :max-height="$vuetify.breakpoint.mdAndUp ? 50 : 40"
          contain
          alt="GameX Sports"
        ></v-img>
        <v-img
          v-else
          class="d-inline-block"
          src="@/assets/GAMEX LOGO FINAL-02.png"
          :max-height="$vuetify.breakpoint.mdAndUp ? 50 : 40"
          contain
          alt="GameX Sports"
        ></v-img>
      </div>

      <!-- <NavbarLinks @logout="logout" /> -->
      <NavbarLinksNew @logout="logout" />
    </v-navigation-drawer>

    <v-app-bar app class="elevation-10">
      <v-btn :color="drawer ? 'primary' : undefined" icon @click="drawer = !drawer">
        <v-icon> mdi-menu </v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <div class="d-flex align-center bs-gap-3 bs-w-100">
        <!-- <LanguageChanger /> -->
        <DarkModeToggle class="me-1" />
        <div class="d-flex align-center bs-gap-3" v-if="!appDataLoading">
          <div
            class="light font-weight-bold rounded-pill d-flex align-center pr-5 py-2"
            :class="isDisableAddToken ? 'pl-5' : 'pl-2'"
            style="height: 40px; color: #fff; line-height: 0.8"
            :style="$vuetify.theme.dark ? 'color: #fff' : 'color: #000'"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <router-link
                  :to="{ name: 'AddPoints' }"
                  v-if="!isDisableAddToken"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-3"
                  style="text-decoration: none"
                >
                  <v-icon color="primary" style="margin-left: 2px">
                    mdi-plus-circle
                  </v-icon>
                </router-link>
              </template>
              Add Token
            </v-tooltip>
            <span class="d-flex align-center bs-gap-3">
              <span class="d-none d-sm-inline">Token</span>
              <span>{{ theActualPoints }}</span>
            </span>
            <div class="d-flex" v-if="isSalesRep">
              <span class="grey--text mx-4" style="opacity: 0.25">|</span>
              {{ forPayout }}
            </div>
          </div>
        </div>
        <div v-if="!appDataLoading && appData">
          <v-menu offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                class="d-flex align-center justify-center light primary--text"
                style="overflow: hidden"
                v-bind="attrs"
                v-on="on"
              >
                <img
                  v-if="appData && appData.account_details.selfie && !image_has_error"
                  :src="appData.account_details.selfie"
                  :alt="appData.account_details.first_name"
                  height="40"
                  @error="image_has_error = true"
                />
                <span class="font-weight-bold" v-else>
                  {{
                    appData.account_details.first_name.substr(0, 1) +
                    appData.account_details.last_name.substr(0, 1)
                  }}
                </span>
              </v-btn>
            </template>
            <v-list class="pt-0 pb-2">
              <div class="d-flex justify-center pa-4">
                <div class="d-flex flex-column align-center" style="position: relative">
                  <div style="position: relative; max-width: 40px; max-height: 40px">
                    <div
                      class="d-flex justify-center align-center rounded-circle overflow-hidden"
                      style="height: 40px; width: 40px"
                      v-if="appData && appData.account_details.selfie && !image_has_error"
                    >
                      <img
                        :src="appData.account_details.selfie"
                        :alt="appData.account_details.first_name"
                        class="d-block"
                        style="width: auto; height: 100%"
                        @error="image_has_error = true"
                      />
                    </div>

                    <div
                      v-else
                      class="font-weight-bold light primary--text rounded-circle d-flex align-center justify-center"
                      style="width: 40px; height: 40px"
                    >
                      {{
                        appData.account_details.first_name.substr(0, 1) +
                        appData.account_details.last_name.substr(0, 1)
                      }}
                    </div>
                  </div>

                  <div
                    class="text-center d-flex flex-column justify-center align-center mt-3"
                  >
                    <span class="d-flex align-center font-weight-bold">
                      <v-icon
                        color="primary"
                        small
                        class="me-2"
                        v-if="accountStatus == 'Verified'"
                      >
                        mdi-check-decagram
                      </v-icon>
                      {{ trimmedUsername(appData.account_details.username) }}
                    </span>
                    <span
                      v-if="appData.account_details.email_address"
                      class="grey--text mt-1"
                      style="font-size: 12px"
                    >
                      {{ appData.account_details.email_address }}
                    </span>
                  </div>
                </div>
              </div>
              <v-divider></v-divider>
              <v-list-item link router :to="{ name: 'Account' }">
                <v-list-item-icon>
                  <v-icon>mdi-account-circle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>My Account</v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item
                v-if="!appDataLoading && !isDisableAddToken"
                link
                router
                :to="{ name: 'AddPoints' }"
              >
                <v-list-item-icon>
                  <v-icon>mdi-plus-circle-multiple-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Add Token</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="!appDataLoading && !isDisableAddToken"
                link
                router
                :to="{ name: 'Withdraw' }"
              >
                <v-list-item-icon>
                  <v-icon>mdi-minus-circle-multiple-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Withdraw</v-list-item-title>
              </v-list-item>
              <v-divider v-if="!appDataLoading && !isDisableAddToken"></v-divider>
              <v-list-item link @click="logout()">
                <v-list-item-icon>
                  <v-icon> mdi-logout-variant</v-icon>
                </v-list-item-icon>
                <v-list-item-title> Logout </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>

      <v-idle @idle="logout" :loop="true" :wait="5" :duration="1800" v-show="false" />
    </v-app-bar>

    <!-- Error -->
    <v-dialog v-model="unauthorizedModal" persistent max-width="400">
      <v-card class="bs-shadow-none">
        <div class="pa-4 pa-sm-6">
          <h3 class="font-weight-bold orange--text mb-3">Warning</h3>
          <div class="grey--text mb-4 mb-sm-6">
            Your account has been logged into another device.
          </div>
          <div class="d-flex justify-end">
            <v-btn class="light" text @click="logout" :disabled="logoutLoading">
              Okay
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- Refresh Error -->
    <v-dialog v-model="refreshErrorModal" persistent max-width="400">
      <v-card class="bs-shadow-none">
        <div class="pa-4 pa-sm-6">
          <h3 class="font-weight-bold orange--text mb-3">Warning</h3>
          <div class="grey--text mb-4">
            {{ `${refreshError}, this page will be refreshed.` }}
          </div>
          <div class="d-flex justify-end">
            <v-btn class="light" text @click="refreshPage"> Okay </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- Ads Modal -->
    <v-dialog v-model="ads_modal" max-width="600" scrollable>
      <div class="dark rounded-lg" style="position: relative; overflow-y: auto">
        <v-btn
          @click="ads_modal = false"
          class="ma-4"
          icon
          small
          style="position: absolute; top: 0; right: 0; z-index: 2"
        >
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
        <AdsModal />
      </div>
    </v-dialog>
  </div>
</template>

<style scoped>
.v-toolbar__content {
  width: 100%;
}
</style>

<script>
// import NavbarLinks from "@/components/app/NavbarLinks";
import NavbarLinksNew from "@/components/app/NavbarLinksNew";
import DarkModeToggle from "@/components/DarkModeToggle";
import AdsModal from "@/components/AdsModal";
// import LanguageChanger from "@/components/LanguageChanger";
import { mapState, mapActions, mapGetters } from "vuex";
import { pusherConnect, pusherDisconnect, pusherTokenSubscribe } from "@/pusher/config";

export default {
  components: {
    // NavbarLinks,
    NavbarLinksNew,
    DarkModeToggle,
    AdsModal,
    // LanguageChanger,
  },
  data: () => ({
    drawer: false,
    unauthorizedModal: false,
    refreshErrorModal: false,
    image_has_error: false,
    ads_modal: true,
  }),
  computed: {
    ...mapState([
      "userData",
      "appData",
      "accountStatus",
      "actualPoints",
      "appDataLoading",
      "error",
      "refreshError",
      "forPayoutToken",
    ]),
    ...mapState("logout", ["logoutLoading"]),
    ...mapGetters(["isAuthenticated"]),
    theActualPoints() {
      if (this.actualPoints) {
        return this.moneyFormat(parseFloat(this.actualPoints));
      } else {
        return 0;
      }
    },
    forPayout() {
      if (this.forPayoutToken) {
        return this.moneyFormat(parseFloat(this.forPayoutToken));
      } else {
        return 0;
      }
    },
    isDisableAddToken() {
      if (this.appData) {
        if (
          this.appData.account_details.agent_sub_type_id == 1 ||
          (this.appData.account_details.investor_sub_type_id == 1 &&
            this.appData.account_details.investor_type_id != 1)
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    isSalesRep() {
      if (
        this.appData &&
        this.appData.account_details.investor_sub_agent_player_id &&
        this.appData.account_details.investor_sub_type_id == 1
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    if (this.isAuthenticated) {
      this.$root.$emit("setOverlay", true);
    }
  },
  mounted() {
    if (this.userData && !this.appData) {
      this.doGetAppData();

      // Connect to pusher
      pusherConnect();

      // Subscribe to token channel
      const tokenChannel = pusherTokenSubscribe(this.userData.uid);

      const addedCallback = (data) => {
        this.$store.commit("setActualPoints", +this.actualPoints + +data.amount);

        const sound = new Audio(require("@/assets/audio/addToken.mp3"));
        sound.play();
      };

      const deductedCallback = (data) => {
        this.$store.commit("setActualPoints", this.actualPoints - data.amount);

        const sound = new Audio(require("@/assets/audio/deductToken.mp3"));
        sound.play();
      };

      tokenChannel.bind("added-token", addedCallback);
      tokenChannel.bind("deducted-token", deductedCallback);
    }

    // if (sessionStorage.getItem("_iamas") == "true") {
    //   this.ads_modal = false;
    // } else {
    //   this.ads_modal = true;
    // }
  },
  methods: {
    ...mapActions("logout", ["doLogout"]),
    ...mapActions(["getAppdata"]),
    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    async doGetAppData() {
      await this.getAppdata();

      this.agent_id = this.appData.account_details.agent_id;
      this.$root.$emit("setOverlay", false);
    },
    async logout(isForce) {
      this.drawer = false;
      this.$root.$emit("setOverlay", true, "logout");

      if (!this.logoutLoading) {
        pusherDisconnect(this.userData.uid);

        await this.doLogout();

        if (isForce) {
          this.$router.push({ name: "Home", query: { is_force: 1 } });
        } else {
          this.$router.push({ name: "Home" });
        }

        this.$root.$emit("setOverlay", false);
      }
    },
    refreshPage() {
      location.reload();
    },
    trimmedUsername(username) {
      if (username.length >= 20) {
        return `${username.substr(0, 20)} ...`;
      }

      return username;
    },
    // adsModalAlreadyShowed() {
    //   sessionStorage.setItem("_iamas", "true");
    //   this.ads_modal = false;
    // },
  },
  watch: {
    error(val) {
      if (val) {
        this.logout(1);
      }
    },
    refreshError(val) {
      if (val) {
        this.refreshErrorModal = true;
      }
    },
    // ads_modal(val) {
    //   if (val == false) {
    //     this.adsModalAlreadyShowed();
    //   }
    // },
  },
};
</script>
