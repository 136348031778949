import jwtdecode from "../helpers/jwtdecode";

const url = process.env.VUE_APP_API_URL + "login";

export default {
  namespaced: true,

  state: {
    error: null,
    accountError: null,
    loginOtpError: null,
    errors: [],
    loading: false,
    otpMobileError: null,
    otpTooManyRequestError: null,
    otpError: null,
    otpLoading: false,
    // verifyOtpError: null,
    // verifyOtpLoading: false,
    // sendOtpError: null,
    // sendOtpLoading: false
  },
  mutations: {
    setError(state, val) {
      state.error = val;
    },
    setAccountError(state, val) {
      state.accountError = val;
    },
    setLoginOtpError(state, val) {
      state.loginOtpError = val;
    },
    setErrors(state, val) {
      state.errors = val;
    },
    setLoading(state, val) {
      state.loading = val;
    },
    setOtpMobileError(state, val) {
      state.otpMobileError = val;
    },
    setOtpTooManyRequestError(state, val) {
      state.otpTooManyRequestError = val;
    },
    setOtpError(state, val) {
      state.otpError = val;
    },
    setOtpLoading(state, val) {
      state.otpLoading = val;
    },
  },

  actions: {
    async doRequest({ commit }, data) {
      commit("setOtpLoading", true);
      commit("setOtpMobileError", null);
      commit("setOtpTooManyRequestError", null);
      commit("setOtpError", null);

      try {
        const response = await fetch(`${url}/otp`, {
          method: "POST",
          headers: {
            Authorization:
              "Basic cGxheWVyVW5BdXRoOkxhc2Nhc2FzR2FtaW5nQDIwMjIhQFBsYXllclVuQXV0aD09LQ==",
          },
          body: data,
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        }
      } catch (error) {
        const errorStatus = error.response.status;

        if (errorStatus == 409) {
          commit("setOtpMobileError", error.res.messages.error);
        } else if (errorStatus == 429) {
          commit("setOtpTooManyRequestError", error.res.messages.error);
        } else if (errorStatus == 422) {
          commit("setOtpMobileError", error.res.messages.mobile_number);
        } else {
          commit("setOtpError", "Something went wrong, please try again!");
          commit("setPopUpError", "Something went wrong, please try again!", {
            root: true,
          });
        }
      } finally {
        commit("setOtpLoading", false);
      }
    },

    async doLogin({ commit }, data) {
      commit("setLoading", true);
      commit("setError", null);
      commit("setAccountError", null);
      commit("setLoginOtpError", null);
      commit("setErrors", []);

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            Authorization:
              "Basic cGxheWVyVW5BdXRoOkxhc2Nhc2FzR2FtaW5nQDIwMjIhQFBsYXllclVuQXV0aD09LQ==",
          },
          body: data,
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          // Reset all main states
          commit("resetStates", "", { root: true });

          const decodedUserData = jwtdecode(res.userData);

          commit("setUserData", decodedUserData, { root: true });
          commit("setUserToken", res.userData, { root: true });
          localStorage.setItem("userToken", res.userData);
          localStorage.setItem("mobileNumber", decodedUserData.mobile_number);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        if (errorStatus == 400) {
          commit("setLoginOtpError", error.res.messages.error);
        } else if (errorStatus == 403) {
          commit("setAccountError", error.res.messages.error);
        } else if (errorStatus == 422) {
          commit("setErrors", error.res.messages);
        } else {
          commit("setError", "Something went wrong, please try again!");
          commit("setPopUpError", "Something went wrong, please try again!", {
            root: true,
          });
        }
      } finally {
        commit("setLoading", false);
      }
    },

    /* async doVerify({ commit, rootState }, data) {
      commit("setVerifyOtpLoading", true)
      try {
        const response = await fetch(url + '/otp_verify', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        });
        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setVerifyOtpError", null) 
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if ([404, 403].includes(errorStatus)) {
          commit("setVerifyOtpError", error.res.messages.error) 
          commit("setPopUpError", error.res.messages.error, { root: true })
        } else if (errorStatus==422) {
          commit("setVerifyOtpError", error.res.messages.otp) 
          commit("setPopUpError", error.res.messages.otp, { root: true })
        } else {
          commit("setVerifyOtpError", "Something went wrong, please try again!") 
          commit("setPopUpError", "Something went wrong, please try again!", { root: true })
        }
      } finally {
        commit("setVerifyOtpLoading", false)
      }
    },
    async doRequest({ commit, rootState }) {
      commit("setSendOtpLoading", true)
      
      try {
        const response = await fetch(url + '/otp', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          }
        });
        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setSendOtpError", null) 
        }
      } catch(error) {
        commit("setSendOtpError", "Something went wrong, please try again!") 
        commit("setPopUpError", "Something went wrong, please try again!", { root: true })
      } finally {
        commit("setSendOtpLoading", false)
      }
    } */
  },
};
