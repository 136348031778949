const isObjectEmpty = (obj) => {
  for (var x in obj) { return false }
  return true
}

const errorFocus = (errors) => {
  let firstField
  let i = 1

  Object.entries(errors).forEach(([key, value]) => {
    if (value && i === 1) {
      firstField = key
      i++
    }
  })

  return firstField
}

const addErrors = () => {
  /* if (!isObjectEmpty(errors)) {
    let firstError = 1
    Object.entries(errors).forEach(([key, value]) => {
      if (value && firstError === 1) {
        document.querySelector('#'+key).focus()

        firstError++
      }
    })
  } */
}

export { isObjectEmpty, errorFocus, addErrors }