const url = process.env.VUE_APP_API_URL + "home/";

export default {
  namespaced: true,

  state: {
    games: [],
    error: null,
    gamesLoading: false,
  },

  mutations: {
    setGames(state, val) {
      state.games = val;
    },
    setError(state, val) {
      state.error = val;
    },
    setGamesLoading(state, val) {
      state.gamesLoading = val;
    },
  },

  actions: {
    async getGames({ commit }) {
      commit("setGamesLoading", true);
      commit("setError", null);

      try {
        const response = await fetch(`${url}todays_games`, {
          method: "GET",
          headers: {
            Authorization:
              "Basic cGxheWVyVW5BdXRoOkxhc2Nhc2FzR2FtaW5nQDIwMjIhQFBsYXllclVuQXV0aD09LQ==",
          },
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setGames", res.records);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        if (errorStatus == 403) {
          commit("setAccountError", error.res.messages.error);
        } else {
          commit("setError", "Something went wrong, please try again!");
          commit("setPopUpError", "Something went wrong, please try again!", {
            root: true,
          });
        }
      } finally {
        commit("setGamesLoading", false);
      }
    },
  },
};
