import store from '../store'
const url = process.env.VUE_APP_API_URL

const request_get = async (payload = '', end_point) => {
  let response_data = {}
  let response_status = 0

  const user_token = store.state.userToken

  try {
    const response = await fetch(`${url}${end_point}?${payload}` , {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${user_token}`,
      }
    });

    const res = await response.json()
    response_status = response.status

    response_data = {
      status: response_status,
      data: res
    } 
    
    return response_data
  } catch(error) {
    response_data = {
      status: response_status,
      data: error
    } 

    return response_data
  } 
}

export default request_get
