const url = process.env.VUE_APP_API_URL + "ending"

export default {
  namespaced: true,

  state: {
    records: [],
    recordsLoading: false,
    slots: [],
    slotsError: null,
    betsName: [],
    betsNameError: null,
    betsNameLoading: false,
    bets: [],
    betsError: null,
    betsLoading: false,
    betError: null,
    betHasError: false,
    betLoading: false
  },
  mutations: {
    setRecords (state, val) {
      state.records = val
    },
    setRecordsLoading (state, val) {
      state.recordsLoading = val
    },
    setSlots (state, val) {
      state.slots = val
    },
    setSlotsError (state, val) {
      state.slotsError = val
    },
    setBetsName (state, val) {
      state.betsName = val
    },
    setBetsNameError (state, val) {
      state.betsNameError = val
    },
    setBetsNameLoading (state, val) {
      state.betsNameLoading = val
    },
    setBets (state, val) {
      state.bets = val
    },
    setBetsError (state, val) {
      state.betsError = val
    },
    setBetsLoading (state, val) {
      state.betsLoading = val
    },
    setBetError (state, val) {
      state.betError = val
    },
    setBetHasError (state, val) {
      state.betHasError = val
    },
    setBetLoading (state, val) {
      state.betLoading = val
    }
  },

  actions: {
    async getRecords({ commit, rootState }, data) {
      commit("setRecordsLoading", true)

      try {
        const response = await fetch(`${url}?${data}` , {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          }
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setRecords", res.games)
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true })
        }
      } finally {
        commit("setRecordsLoading", false)
      }
    },

    async getSlots({ commit, rootState }, data) {
      commit("setSlotsError", null) 

      try {
        const response = await fetch(`${url}/slots?${data}` , {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          }
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setSlots", res.slots)
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else {
          commit("setSlotsError", "Something went wrong, please try again!")
          commit("setPopUpError", "Something went wrong, please try again!", { root: true })
        }
      }
    },

    async getBetsName({ commit, rootState }, data) {
      commit("setBetsNameLoading", true)
      commit("setBetsNameError", null) 

      try {
        const response = await fetch(`${url}/bets_name?${data}` , {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          }
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setBetsName", res)
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else {
          commit("setBetsNameError", "Something went wrong, please try again!")
          commit("setPopUpError", "Something went wrong, please try again!", { root: true })
        }
      } finally {
        commit("setBetsNameLoading", false)
      }
    },

    async getBets({ commit, rootState }, data) {
      commit("setBetsLoading", true)
      commit("setBetsError", null) 

      try {
        const response = await fetch(`${url}/bets?${data}` , {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          }
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          // Add isShow
          const betsWithIsShow = res.bets.map((bet) => {
            return { ...bet, isShow: false }
          })

          commit("setBets", betsWithIsShow)
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else {
          commit("setBetsError", "Something went wrong, please try again!")
          commit("setPopUpError", "Something went wrong, please try again!", { root: true })
        }
      } finally {
        commit("setBetsLoading", false)
      }
    },

    async doBet({ commit, rootState }, data) {
      commit("setBetLoading", true)
      commit("setBetError", null) 
      commit("setBetHasError", false) 

      try {
        const response = await fetch(`${url}/bet` , {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus==404) {
          commit("setBetError", error.res.messages.error)
        } else {
          commit("setPopUpError", "Something went wrong, please try again!", { root: true })
        }

        commit("setBetHasError", true) 
      } finally {
        commit("setBetLoading", false)
      }
    },
  }
}