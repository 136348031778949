<template>
  <v-carousel delimiter-icon="mdi-circle" show-arrows-on-hover height="auto" cycle>
    <v-carousel-item>
      <v-img
        :src="
          getImgSrc(
            '0-02-06-e914916572b22dee7d8be85411ce4d539d2b83de75187889ff4d2a57ea460c5f_97d990e5d87e251f.jpg'
          )
        "
        style="width: auto; height: 100%"
      >
      </v-img>
    </v-carousel-item>
    <v-carousel-item>
      <v-img
        :src="getImgSrc('viber_image_2023-01-27_16-56-38-463.jpg')"
        style="width: 100%; height: auto"
      >
      </v-img>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  methods: {
    getImgSrc(src) {
      return require("@/assets/images/ads/" + src);
    },
  },
};
</script>
