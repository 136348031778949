<template>
  <div
    class="pb-4 pe-4"
    style="position: fixed; right: 0; z-index: 120; transition: bottom ease 0.3s"
    :style="show_element ? 'bottom: 0' : 'bottom: calc(-88px + -1rem)'"
  >
    <div class="rounded-pill elevation-10 d-flex flex-column bs-gap-2">
      <v-btn class="light secondary--text" icon @click="setDialogs('register')" dark>
        <i class="fas fa-user-plus"></i>
      </v-btn>
      <v-btn class="light primary--text" icon @click="setDialogs('login')" dark>
        <i class="fas fa-sign-in-alt"></i>
      </v-btn>
    </div>
  </div>
</template>

<style scoped>
.v-btn {
  width: 100%;
}
</style>

<script>
export default {
  data: () => ({
    last_scroll_position: 0,
    show_element: true,
  }),
  mounted() {
    window.addEventListener("scroll", this.handle_scroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handle_scroll);
  },
  methods: {
    setDialogs(val) {
      this.$root.$emit("setDialogs", val);
    },
    handle_scroll() {
      const currentScrollPosition = window.pageYOffset;

      if (currentScrollPosition > this.last_scroll_position) {
        this.show_element = false;
      } else {
        this.show_element = true;
      }

      this.last_scroll_position = currentScrollPosition;
    },
  },
};
</script>
