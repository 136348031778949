<template>
  <v-snackbar v-model="snackbar" top :color="snackbarData.snackbarColor">
    <v-icon class="me-2"> mdi-check-circle-outline </v-icon>
    {{ snackbarData.snackbarMsg }}
    <template v-slot:action="{ attrs }">
      <v-btn icon v-bind="attrs" @click="setSnackbar" small>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    snackbarData: {
      required: true,
    },
  },
  computed: {
    snackbar: {
      get: function () {
        return true;
      },
      set: function () {
        this.setSnackbar();
      },
    },
  },
  methods: {
    setSnackbar() {
      this.$emit("setSnackbar");
    },
  },
};
</script>
