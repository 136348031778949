const url = process.env.VUE_APP_API_URL + "payouts"

export default {
  namespaced: true,

  state: {
    records: [],
    recordsLoading: false,
    requestLoading: false,
    requestError: null,
    requestHasError: false,
  },
  mutations: {
    setRecords (state, val) {
      state.records = val
    },
    setRecordsLoading (state, val) {
      state.recordsLoading = val
    },
    setRequestLoading (state, val) {
      state.requestLoading = val
    },
    setRequestError (state, val) {
      state.requestError = val
    },
    setRequestHasError (state, val) {
      state.requestHasError = val
    },
  },

  actions: {
    async getRecords({ commit, rootState }, data) {
      commit("setRecordsLoading", true)

      try {
        const response = await fetch(`${url}?${data}` , {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          }
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setRecords", res.payouts)
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true })
        }
      } finally {
        commit("setRecordsLoading", false)
      }
    },

    async doRequest({ commit, rootState }, data) {
      commit("setRequestLoading", true)
      commit("setRequestError", null) 
      commit("setRequestHasError", false) 

      try {
        const response = await fetch(`${url}/request` , {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus==404) {
          commit("setRequestError", error.res.messages.error)
        } else {
          commit("setPopUpError", "Something went wrong, please try again!", { root: true })
        }

        commit("setRequestHasError", true) 
      } finally {
        commit("setRequestLoading", false)
      }
    },
  }
}