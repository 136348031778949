import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import store from "../store";

Vue.use(VueRouter);

const guest = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    return next();
  } else {
    return next({ name: "EndingGames" });
  }
};

const auth = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    return next();
  } else {
    return next({ name: "Login" });
  }
};

const routes = [
  {
    path: "/home",
    alias: "/",
    name: "Home",
    // route level code-splitting
    // this generates a separate chunk (home.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/HomeNew.vue"),
    beforeEnter: guest,
    meta: {
      isPublic: true,
    },
  },

  {
    path: "/:referral_type/:sub_type/:referral_code",
    name: "HomeRegister",
    // route level code-splitting
    // this generates a separate chunk (home.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/HomeNew.vue"),
    beforeEnter: guest,
    meta: {
      isPublic: true,
    },
  },

  {
    path: "/mpbl",
    redirect: '/vNG9y/lygzW/8Ovny'
  },

  {
    path: "/about_us",
    name: "AboutUs",
    // route level code-splitting
    // this generates a separate chunk (aboutus.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "aboutus" */ "../views/AboutUs.vue"),
    beforeEnter: guest,
    meta: {
      isPublic: true,
    },
  },

  {
    path: "/terms_of_use",
    name: "TermsConditions",
    // route level code-splitting
    // this generates a separate chunk (termsandconditions.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "termsandconditions" */ "../views/TermsConditions.vue"
      ),
    beforeEnter: guest,
    meta: {
      isPublic: true,
    },
  },

  {
    path: "/contact_us",
    name: "ContactUs",
    // route level code-splitting
    // this generates a separate chunk (contactus.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "contactus" */ "../views/ContactUs.vue"),
    beforeEnter: guest,
    meta: {
      isPublic: true,
    },
  },

  {
    path: "/responsible_gaming",
    name: "ResponsibleGaming",
    // route level code-splitting
    // this generates a separate chunk (responsiblegaming.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "responsiblegaming" */ "../views/ResponsibleGaming.vue"
      ),
    beforeEnter: guest,
    meta: {
      isPublic: true,
    },
  },

  {
    path: "/privacy_policy",
    name: "PrivacyPolicy",
    // route level code-splitting
    // this generates a separate chunk (privacypolicy.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "privacypolicy" */ "../views/PrivacyPolicy.vue"
      ),
    beforeEnter: guest,
    meta: {
      isPublic: true,
    },
  },

  {
    path: "/reset_password/:id",
    name: "ResetPassword",
    // route level code-splitting
    // this generates a separate chunk (resetpassword.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "resetpassword" */ "../views/ResetPassword.vue"
      ),
    props: true,
    beforeEnter: guest,
    meta: {
      isPublic: true,
    },
  },

  {
    path: "/app/home",
    name: "AppHome",
    // route level code-splitting
    // this generates a separate chunk (endinggames.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "endinggames" */ "../views/app/AppHome.vue"),
    beforeEnter: auth,
  },

  {
    path: "/app/ending/games",
    name: "EndingGames",
    // route level code-splitting
    // this generates a separate chunk (endinggames.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "endinggames" */ "../views/app/ending/Games.vue"
      ),
    beforeEnter: auth,
  },

  {
    path: "/app/ending/transactions",
    name: "EndingMyBets",
    // route level code-splitting
    // this generates a separate chunk (endingmybets.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "endingmybets" */ "../views/app/ending/MyBets.vue"
      ),
    beforeEnter: auth,
  },

  {
    path: "/app/transactions",
    name: "MyBets",
    // route level code-splitting
    // this generates a separate chunk (endingmybets.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "endingmybets" */ "../views/app/MyBets.vue"),
    beforeEnter: auth,
  },

  {
    path: "/app/news_and_updates",
    name: "NewsAndUpdates",
    // route level code-splitting
    // this generates a separate chunk (endingmybets.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "newsandupdates" */ "../views/app/NewsAndUpdates.vue"
      ),
    beforeEnter: auth,
  },

  {
    path: "/app/tutorial",
    name: "Tutorial",
    // route level code-splitting
    // this generates a separate chunk (tutorial.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "tutorial" */ "../views/app/Tutorial.vue"),
    beforeEnter: auth,
  },

  {
    path: "/app/add_token",
    name: "AddPoints",
    // route level code-splitting
    // this generates a separate chunk (addpoints.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "addpoints" */ "../views/app/AddPoints.vue"),
    beforeEnter: auth,
  },

  {
    path: "/app/add_token/history",
    name: "AddPointsHistory",
    // route level code-splitting
    // this generates a separate chunk (addpointshistory.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addpointshistory" */ "../views/app/AddPointsHistory.vue"
      ),
    beforeEnter: auth,
  },

  {
    path: "/app/withdraw",
    name: "Withdraw",
    // route level code-splitting
    // this generates a separate chunk (withdraw.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "withdraw" */ "../views/app/Withdraw.vue"),
    beforeEnter: auth,
  },

  {
    path: "/app/withdraw/history",
    name: "WithdrawHistory",
    // route level code-splitting
    // this generates a separate chunk (withdrawhistory.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "withdrawhistory" */ "../views/app/WithdrawHistory.vue"
      ),
    beforeEnter: auth,
  },

  {
    path: "/app/my_account",
    name: "Account",
    // route level code-splitting
    // this generates a separate chunk (account.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "account" */ "../views/app/Account.vue"),
    beforeEnter: auth,
  },

  {
    path: "/app/my_commissions",
    name: "MyCommissions",
    // route level code-splitting
    // this generates a separate chunk (myCommission.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "myCommission" */ "../views/app/MyCommissions.vue"
      ),
    beforeEnter: auth,
  },

  {
    path: "/app/lucky_four/games",
    name: "LuckyFourGames",
    // route level code-splitting
    // this generates a separate chunk (luckyFourGames.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "luckyFourGames" */ "../views/app/lucky_four/GamesNew.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/lucky_four/my_bets",
    name: "LuckyFourMyBets",
    // route level code-splitting
    // this generates a separate chunk (luckyFourMyBets.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "luckyFourMyBets" */ "../views/app/lucky_four/MyBets.vue"
      ),
    beforeEnter: auth,
  },

  {
    path: "/app/payouts",
    name: "Payouts",
    // route level code-splitting
    // this generates a separate chunk (myCommission.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "myCommission" */ "../views/app/Payouts.vue"),
    beforeEnter: auth,
  },

  {
    path: "/app/power_ball",
    name: "PowerBallHome",
    // route level code-splitting
    // this generates a separate chunk (PowerBallGames.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "PowerBallGames" */ "../views/app/power_ball/Home.vue"
      ),
    beforeEnter: auth,
  },

  {
    path: "/app/power_ball/games",
    name: "PowerBallGames",
    // route level code-splitting
    // this generates a separate chunk (PowerBallGames.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "PowerBallGames" */ "../views/app/power_ball/Games.vue"
      ),
    beforeEnter: auth,
  },

  // {
  //   path: "/app/power_ball/bets",
  //   name: "PowerBallBets",
  //   // route level code-splitting
  //   // this generates a separate chunk (powerballbets.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "powerballbets" */ "../views/app/power_ball/MyBets.vue"
  //     ),
  //   beforeEnter: auth,
  // },

  {
    path: "/",
    name: "Maintenance",
    component: () =>
      import(/* webpackChunkName: "maintenance" */ "../views/Maintenance.vue"),
  },

  // catchall 404
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "pagenotfound" */ "../views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash,
      };
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start();
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
