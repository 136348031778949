const url = process.env.VUE_APP_API_URL + "account"

export default {
  namespaced: true,

  state: {
    verifyError: null,
    verifyLoading: false,
    updateValidIDHasError: false,
    updateValidIDLoading: false,
    updateSelfieHasError: false,
    updateSelfieLoading: false,
    changePasswordError: null,
    changePasswordLoading: false,
    personalInfoError: null,
    personalInfoErrors: [],
    personalInfoLoading: false,
    updatePicturesHasError: false,
    // updatePicturesError: null,
    updatePicturesLoading: false,
  },
  mutations: {
    setVerifyError(state, val) {
      state.verifyError = val
    },
    setVerifyLoading(state, val) {
      state.verifyLoading = val
    },
    setUpdateValidIDHasError(state, val) {
      state.updateValidIDHasError = val
    },
    setUpdateValidIDLoading(state, val) {
      state.updateValidIDLoading = val
    },
    setUpdateSelfieHasError(state, val) {
      state.updateSelfieHasError = val
    },
    setUpdateSelfieLoading(state, val) {
      state.updateSelfieLoading = val
    },
    setChangePasswordError(state, val) {
      state.changePasswordError = val
    },
    setChangePasswordLoading(state, val) {
      state.changePasswordLoading = val
    },
    setPersonalInfoError(state, val) {
      state.personalInfoError = val
    },
    setPersonalInfoErrors(state, val) {
      state.personalInfoErrors = val
    },
    setPersonalInfoLoading(state, val) {
      state.personalInfoLoading = val
    },
    setUpdatePicturesHasError(state, val) {
      state.updatePicturesHasError = val
    },
    /* setUpdatePicturesError(state, val) {
      state.updatePicturesError = val
    }, */
    setUpdatePicturesLoading(state, val) {
      state.updatePicturesLoading = val
    },
  },

  actions: {
    async doVerify({ commit, rootState }, data) {
      commit("setVerifyLoading", true)
      commit("setVerifyError", null) 

      try {
        const response = await fetch(`${url}/verify`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        });

        const res = await response.json()

        // alert(JSON.stringify(res))
        
        if (!response.ok) {
          throw { response, res }
        } else {
          return res
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else {
          commit("setVerifyError", "Something went wrong, please try again!") 
          commit("setPopUpError", "Something went wrong, please try again!", { root: true })
        }
      } finally {
        commit("setVerifyLoading", false)
      }
    },

    async doUpdateValidID({ commit, rootState }, data) {
      commit("setUpdateValidIDLoading", true)
      commit("setUpdateValidIDHasError", false) 

      try {
        const response = await fetch(`${url}/update_valid_id`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        })

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          return res
        }
      } catch(error) {
        const errorStatus = error.response.status

        commit("setUpdateValidIDHasError", true) 
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus==422) {
          commit("setPopUpError", "There's something wrong with the image you have selected, please try to use other images.", { root: true })
        } else {
          commit("setPopUpError", "Something went wrong, please try again!", { root: true })
        }
      } finally {
        commit("setUpdateValidIDLoading", false)
      }
    },

    async doUpdateSelfie({ commit, rootState }, data) {
      commit("setUpdateSelfieLoading", true)
      commit("setUpdateSelfieHasError", false) 

      try {
        const response = await fetch(`${url}/update_selfie`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        })

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          return res
        }
      } catch(error) {
        const errorStatus = error.response.status

        commit("setUpdateSelfieHasError", true) 
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus==422) {
          commit("setPopUpError", "There's something wrong with the image you have selected, please try to use other images.", { root: true })
        } else {
          commit("setPopUpError", "Something went wrong, please try again!", { root: true })
        }
      } finally {
        commit("setUpdateSelfieLoading", false)
      }
    },

    async doChangePassword({ commit, rootState }, data) {
      commit("setChangePasswordLoading", true)
      commit("setChangePasswordError", null) 

      try {
        const response = await fetch(`${url}/change_password`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        })

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          return res
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus==403) {
          commit("setChangePasswordError", error.res.messages.error) 
        } else {
          commit("setChangePasswordError", "Something went wrong, please try again!") 
          commit("setPopUpError", "Something went wrong, please try again!", { root: true })
        }
      } finally {
        commit("setChangePasswordLoading", false)
      }
    },

    async updatePersonalInfo({ commit, rootState }, data) {
      commit("setPersonalInfoLoading", true)
      commit("setPersonalInfoError", null) 
      commit("setPersonalInfoErrors", []) 

      try {
        const response = await fetch(`${url}/update_personal_info`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        })

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          return res
        }
      } catch(error) {
        const errorStatus = error.response.status

        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus==422) {
          commit("setPersonalInfoErrors", error.res.messages) 
        } else {
          commit("setPersonalInfoError", "Something went wrong, please try again!") 
          commit("setPopUpError", "Something went wrong, please try again!", { root: true })
        }
      } finally {
        commit("setPersonalInfoLoading", false)
      }
    },

    async doUpdatePictures({ commit, rootState }, data) {
      commit("setUpdatePicturesLoading", true)
      commit("setUpdatePicturesHasError", false) 
      // commit("setUpdatePicturesError", null) 

      try {
        const response = await fetch(`${url}/update_pictures`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        })

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          return res
        }
      } catch(error) {
        const errorStatus = error.response.status

        commit("setUpdatePicturesHasError", true)
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus==422) {
          commit("setPopUpError", "There's something wrong with the image you have selected, please try to use other images.", { root: true })
        } else {
          commit("setPopUpError", "Something went wrong, please try again!", { root: true })
        }
      } finally {
        commit("setUpdatePicturesLoading", false)
      }
    },
  }
}