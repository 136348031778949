import Pusher from 'pusher-js'
// import store from '../store'

let pusher

const pusherConnect = () => {
  if (!pusher) {
    pusher = new Pusher(process.env.VUE_APP_PUSHER_ID, {
      cluster: 'ap1'
    })
  }

  // pusher.connection.bind("connected", function () {
  //   console.log("Realtime is go!")
  // })
}

/* SUBSCRIBE */
const pusherTokenSubscribe = (userId) => {
  const channel = pusher.subscribe('user-token-' + userId)

  return channel
}

const pusherAvailableSlotsSubscribe = (gameId, slotId) => {
  const channel = pusher.subscribe(`available-slots-${gameId}-${slotId}`)

  return channel
}

/* UNSUBSCRIBE */
const pusherAvailableSlotsUnsubscribe = (gameId, slotId) => {
  pusher.unsubscribe(`available-slots-${gameId}-${slotId}`)
}

const pusherDisconnect = (userId) => {
  pusher.unsubscribe('user-token-' + userId)
}

export { 
  pusherConnect, 
  pusherDisconnect, 
  pusherTokenSubscribe, 
  pusherAvailableSlotsSubscribe, 
  pusherAvailableSlotsUnsubscribe 
}