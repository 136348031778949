import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      light: {
        primary: "#00c8ff",
        secondary: "#ffc82d",
        dark: "#ffffff",
        light: "#ebf2f5",
        lighter: "#f1f8fb",
        "v-card": "#ffffff",
        "v-list": "#ffffff",
        "v-main": "#ebf2f5",
        "v-navigation-drawer": "#ffffff",
        "v-app-bar": "#ffffff",
        footer: "#ffffff",
      },

      // Dark Theme 1
      // dark: {
      //   primary: "#00c8ff",
      //   secondary: "#ffc82d",
      //   dark: "#081e2a",
      //   light: "#0d2f43",
      //   lighter: "#113b53",
      //   "v-card": "#081e2a",
      //   "v-list": "#113b53",
      //   "v-main": "#06141c",
      //   "v-navigation-drawer": "#0a2535",
      //   "v-app-bar": "#081e2a",
      //   footer: "#081e2a",
      // },

      // Dark Theme 2
      // dark: {
      //   primary: "#00c8ff",
      //   secondary: "#ffc82d",
      //   dark: "#161423",
      //   light: "#1e1c31",
      //   lighter: "#2b2743",
      //   "v-card": "#161423",
      //   "v-list": "#161423",
      //   "v-main": "#0e0c1a",
      //   "v-navigation-drawer": "#201d32",
      //   "v-app-bar": "#161423",
      //   footer: "#161423",
      // },

      // Dark Theme 3
      // dark: {
      //   primary: "#00c8ff",
      //   secondary: "#ffc82d",
      //   dark: "#171717",
      //   light: "#222222",
      //   lighter: "#242424",
      //   "v-card": "#171717",
      //   "v-list": "#171717",
      //   "v-main": "#111111",
      //   "v-navigation-drawer": "#191919",
      //   "v-app-bar": "#191919",
      //   footer: "#171717",
      // },

       // Dark Theme 4
       dark: {
        primary: "#00c8ff",
        secondary: "#ffc82d",
        dark: "#001c31",
        light: "#002847",
        lighter: "#003156",
        "v-card": "#001c31",
        "v-list": "#00335a",
        "v-main": "#00111e",
        "v-navigation-drawer": "#00233d",
        "v-app-bar": "#001a2c",
        footer: "#001a2c",
      },
    },
  },
  breakpoint: {
    thresholds: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 900,
      xl: 1200,
    },
  },
});
