const url = process.env.VUE_APP_API_URL + "reset_password"

export default {
  namespaced: true,

  state: {
    requestError: null,
    requestIdError: null,
    requestLoading: false,
    error: null,
    resetError: null,
    loading: false
  },
  mutations: {
    setRequestError(state, val) {
      state.requestError = val
    },
    setRequestIdError(state, val) {
      state.requestIdError = val
    },
    setRequestLoading(state, val) {
      state.requestLoading = val
    },
    setError(state, val) {
      state.error = val
    },
    setResetError(state, val) {
      state.resetError = val
    },
    setLoading(state, val) {
      state.loading = val
    },
  },

  actions: {
    async doCheckRequest({ commit }, data) {
      commit("setRequestLoading", true)
      commit("setRequestIdError", null) 
      commit("setRequestError", null) 

      try {
        const response = await fetch(`${url}/check`, {
          method: 'POST',
          headers: {
            'Authorization': 'Basic cGxheWVyVW5BdXRoOkxhc2Nhc2FzR2FtaW5nQDIwMjIhQFBsYXllclVuQXV0aD09LQ==',
          },
          body: data
        })

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          return res
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==403) {
          commit("setRequestIdError", error.res.messages.error) 
        } else {
          commit("setRequestError", "Something went wrong, please try again!") 
          commit("setPopUpError", "Something went wrong, please try again!", { root: true })
        }
      } finally {
        commit("setRequestLoading", false)
      }
    },

    async doReset({ commit }, data) {
      commit("setLoading", true)
      commit("setError", null) 
      commit("setResetError", null) 

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Authorization': 'Basic cGxheWVyVW5BdXRoOkxhc2Nhc2FzR2FtaW5nQDIwMjIhQFBsYXllclVuQXV0aD09LQ==',
          },
          body: data
        })

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          return res
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==403) {
          commit("setResetError", error.res.messages.error) 
        } else {
          commit("setError", "Something went wrong, please try again!") 
          commit("setPopUpError", "Something went wrong, please try again!", { root: true })
        }
      } finally {
        commit("setLoading", false)
      }
    },
  }
}