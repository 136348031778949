<template>
  <v-dialog v-model="dialog" :persistent="loading" scrollable max-width="700">
    <v-card class="login bs-shadow-none" style="border: none !important">
      <div style="overflow-y: auto">
        <div class="bs-row bs-g-0">
          <div
            class="light left-panel bs-col-md-6"
            v-if="$vuetify.breakpoint.mdAndUp"
          ></div>
          <div class="bs-col-md-6">
            <div class="pa-6 pa-md-8" style="overflow-y: auto">
              <v-btn icon @click="setDialogs('login')" small class="float-end">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <div class="mb-3">
                <img
                  v-if="$vuetify.theme.dark"
                  src="@/assets/GAMEX LOGO FINAL 3-02.png"
                  style="width: auto"
                  :style="$vuetify.breakpoint.mdAndUp ? 'height: 50px' : 'height: 40px'"
                  alt="GameX Sports"
                />
                <img
                  v-else
                  src="@/assets/GAMEX LOGO FINAL-02.png"
                  style="width: auto"
                  :style="$vuetify.breakpoint.mdAndUp ? 'height: 50px' : 'height: 40px'"
                  alt="GameX Sports"
                />
              </div>
              <div class="grey--text mb-8 mb-md-12">Please login to continue</div>
              <div>
                <v-form ref="form" @submit.prevent="handleSubmit">
                  <div class="d-flex flex-column bs-gap-3">
                    <div style="position: relative">
                      <v-text-field
                        ref="mobile_number"
                        v-model="mobile_number"
                        :rules="[
                          rules.required,
                          rules.validMobileNumber,
                          mobileNumberError,
                          tooManyAttempts,
                        ]"
                        prefix="+63"
                        label="Mobile Number"
                        outlined
                        block
                        dense
                        maxlength="10"
                        hide-details="auto"
                      ></v-text-field>
                      <div
                        v-if="pagcorMode || enableOtp"
                        class="d-flex align-center pe-3"
                        style="
                          margin-top: 9px;
                          position: absolute;
                          top: 0;
                          right: 0;
                          z-index: 2;
                        "
                      >
                        <a
                          href="#"
                          @click.prevent="handleClick"
                          :style="
                            !hasValidMobileNumber || otpLoading || !requestOtp
                              ? 'pointer-events: none; opacity: 0.5;'
                              : undefined
                          "
                        >
                          <v-progress-circular
                            v-if="otpLoading"
                            indeterminate
                            :size="14"
                            :width="2"
                            color="primary"
                          ></v-progress-circular>
                          <span v-else>Send OTP</span>
                          <span v-if="countDown && !requestOtp">{{
                            ` (${countDown})`
                          }}</span>
                        </a>
                      </div>
                    </div>

                    <v-text-field
                      v-if="!enableOtp"
                      ref="password"
                      v-model="password"
                      :rules="[rules.required, passwordError]"
                      :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show2 ? 'text' : 'password'"
                      label="Password"
                      outlined
                      dense
                      block
                      @click:append="show2 = !show2"
                      hide-details="auto"
                    ></v-text-field>
                    <div v-if="pagcorMode || enableOtp">
                      <div style="position: relative">
                        <v-text-field
                          ref="otp"
                          v-model="otp"
                          :rules="[
                            rules.required,
                            rules.minimum(6),
                            rules.numeric,
                            otpHasError,
                          ]"
                          label="OTP"
                          placeholder="XXXXXX"
                          outlined
                          dense
                          maxlength="6"
                          hide-details="auto"
                        ></v-text-field>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex bs-gap-3 mt-3">
                    <a href="#" @click.prevent="enableOtp = !enableOtp">
                      <span v-if="enableOtp">Password Login</span>
                      <span v-else>OTP Login</span>
                    </a>
                    <v-spacer></v-spacer>
                    <a href="#" v-if="!enableOtp" @click.prevent="setDialogs('forgot')"
                      >Forgot Password?</a
                    >
                  </div>

                  <div v-if="pagcorMode">
                    <v-checkbox
                      v-model="checkbox"
                      :rules="[(v) => !!v || 'You must agree to continue!']"
                      class="mt-0 pt-0"
                    >
                      <template v-slot:label>
                        <div style="margin-top: 1.5px">
                          I accept the
                          <a target="_blank" href="/terms_of_use" @click.stop>
                            Terms of Use
                          </a>
                          and
                          <a target="_blank" href="/privacy_policy" @click.stop>
                            Policy Privacy
                          </a>
                        </div>
                      </template>
                    </v-checkbox>
                  </div>
                  <v-checkbox
                    v-model="checkbox"
                    :rules="[(v) => !!v || 'You must agree to continue.']"
                    class="mt-4 pt-0"
                    hide-details="true"
                  >
                    <template v-slot:label>
                      <div style="margin-top: 1.5px">
                        I read and agree to the
                        <a target="_blank" href="/terms_of_use" @click.stop>
                          Terms of Use
                        </a>
                        and
                        <a target="_blank" href="/privacy_policy" @click.stop>
                          Policy Privacy
                        </a>
                        of GameX Sports.
                      </div>
                    </template>
                  </v-checkbox>
                  <div class="mt-4 mt-sm-6">
                    <v-btn
                      block
                      depressed
                      color="primary"
                      type="submit"
                      :loading="loading"
                      class="justify-space-between py-5 px-3"
                    >
                      Login
                      <v-icon> mdi-arrow-right-thin</v-icon>
                    </v-btn>
                  </div>
                  <!-- <v-alert text color="blue" class="mt-4" role="alert">
                    By clicking the "Login" button, you understand and agree to the
                    <a
                      href="/privacy_policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      
                      >Privacy Policy</a
                    >
                    &
                    <a
                      href="/privacy_policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      
                      >Terms of Use</a
                    >
                    of GameX Sports.
                  </v-alert> -->
                </v-form>

                <p class="mt-4 mt-sm-6 text-center mb-0">
                  Don't have an account yet?
                  <br />
                  <a
                    href="#"
                    text
                    style="text-deco"
                    @click.prevent="setDialogs('register')"
                    class="primary--text text-capitalize"
                  >
                    Signup
                  </a>
                </p>
                <!-- <div
                class="d-flex justify-center text-center bs-gap-2 mt-4 mt-sm-6 mt-md-10"
                style="font-size: 10px"
              >
                <a href="/privacy_policy" target="_blank" rel="noopener noreferrer"
                  >Privacy Policy</a
                >
                |
                <a href="/privacy_policy" target="_blank" rel="noopener noreferrer"
                  >Terms of Use</a
                >
              </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>

    <AppbarSnack
      v-if="snackbar"
      :snackbarData="snackbarData"
      @setSnackbar="setSnackbar"
    />
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { isObjectEmpty } from "@/helpers/form";
import formRules from "@/helpers/formRules";
import AppbarSnack from "@/components/AppbarSnack";

export default {
  components: { AppbarSnack },
  props: {
    route: {
      type: String,
    },
  },

  data: () => ({
    rules: formRules,
    mobile_number: "",
    mobileNumberErrorText: "",
    mobileNumberErrorDesc: "",
    show2: false,
    password: "",
    passwordErrorText: "",
    isForced: false,
    tooManyAttemptsText: "",
    otp: "",
    otpErrorText: "",
    otpErrorDesc: "",
    countDown: 0,
    requestOtp: true,
    checkbox: false,
    snackbar: false,
    snackbarData: {
      snackbarColor: "success",
      snackbarMsg: "The OTP has been sent to your phone.",
    },
    enableOtp: true,
  }),
  computed: {
    ...mapState(["pagcorMode"]),
    ...mapState("login", [
      "error",
      "accountError",
      "loginOtpError",
      "errors",
      "loading",
      "otpMobileError",
      "otpTooManyRequestError",
      "otpError",
      "otpLoading",
    ]),
    dialog: {
      get: function () {
        return true;
      },
      set: function () {
        this.setDialogs("login");
      },
    },
    hasValidMobileNumber() {
      return this.mobile_number &&
        this.rules.validMobileNumber(this.mobile_number) == true &&
        this.mobileNumberError(this.mobile_number) == true &&
        this.tooManyAttempts(this.mobile_number) == true
        ? true
        : false;
    },
  },
  mounted() {
    const loginMN = localStorage.getItem("mobileNumber");

    if (loginMN) {
      this.mobile_number = loginMN;
    }

    this.$refs.mobile_number.focus();

    if (Object.keys(this.$route.query).length) {
      let urlParams = new URLSearchParams(window.location.search);

      let is_force = urlParams.get("is_force");

      if (is_force == "1") {
        this.isForced = true;
      }
    }
  },
  methods: {
    ...mapActions("login", ["doLogin", "doRequest"]),
    setDialogs(selectedDialog) {
      this.$emit("setDialogs", selectedDialog);
    },
    mobileNumberError(val) {
      if (val == this.mobileNumberErrorText) {
        return this.mobileNumberErrorDesc;
      } else {
        return true;
      }
    },
    passwordError(val) {
      if (val == this.passwordErrorText) {
        return "Wrong password.";
      } else {
        return true;
      }
    },
    tooManyAttempts(val) {
      if (val == this.tooManyAttemptsText) {
        return "Too many attempts, please try again after 30 minutes.";
      } else {
        return true;
      }
    },
    otpHasError(val) {
      if (val == this.otpErrorText) {
        return this.otpErrorDesc;
      } else {
        return true;
      }
    },
    countDownTimer(timer) {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);

        this.requestOtp = false;
      } else {
        this.countDown = timer;
        this.requestOtp = true;
      }
    },
    async handleClick() {
      if (!this.otpLoading && this.requestOtp) {
        const formData = new FormData();
        formData.append("mobile_number", this.mobile_number);

        await this.doRequest(formData);

        if (!this.otpMobileError && !this.otpTooManyRequestError && !this.otpError) {
          this.countDown = 60;
          this.countDownTimer(60);

          this.snackbar = true;
        } else {
          if (this.otpMobileError) {
            this.mobileNumberErrorText = this.mobile_number;
            this.mobileNumberErrorDesc = this.otpMobileError;
            this.$refs.mobile_number.validate();
          }

          if (this.otpTooManyRequestError) {
            this.tooManyAttemptsText = this.mobile_number;
            this.$refs.mobile_number.validate();

            this.countDown = 1800;
            this.countDownTimer(1800);
          }
        }
      }
    },
    async handleSubmit() {
      this.mobileNumberErrorText = "";
      this.mobileNumberErrorDesc = "";
      this.passwordErrorText = "";
      this.tooManyAttemptsText = "";

      if (this.$refs.form.validate()) {
        if (!this.loading) {
          const formData = new FormData();
          formData.append("mobile_number", this.mobile_number);
          formData.append("password", this.password);
          formData.append("otp", this.otp);
          formData.append("enable_otp", this.enableOtp);

          await this.doLogin(formData);

          if (
            !this.error &&
            !this.accountError &&
            !this.loginOtpError &&
            isObjectEmpty(this.errors)
          ) {
            this.$router.push({
              name: this.route ? this.route : "EndingGames",
            });
          } else {
            if (this.accountError) {
              if (this.accountError == "This account is deactivated.") {
                this.mobileNumberErrorText = this.mobile_number;
                this.mobileNumberErrorDesc = this.accountError;
                this.$refs.mobile_number.validate();
                this.$refs.mobile_number.focus();
              } else {
                this.passwordErrorText = this.password;
                this.$refs.password.validate();
                this.$refs.password.focus();
              }
            }

            if (this.loginOtpError) {
              this.otpErrorDesc = this.loginOtpError;
              this.otpErrorText = this.otp;
              this.$refs.otp.validate();
              this.$refs.otp.focus();
            }

            if (!isObjectEmpty(this.errors)) {
              let errorFocus = "";

              Object.entries(this.errors).forEach(([key, value]) => {
                if (value) {
                  if (!errorFocus) {
                    errorFocus = key;
                  }

                  if (key == "mobile_number") {
                    this.mobileNumberErrorText = this.mobile_number;
                    this.mobileNumberErrorDesc = "This mobile number is not registered.";
                  }
                }
              });

              this.$refs.form.validate();

              this.$refs[errorFocus].focus();
            }
          }
        }
      }
    },
    setSnackbar() {
      this.snackbar = false;
    },
  },
  watch: {
    mobile_number(val) {
      this.mobile_number = val.replace(/^0+/, "");
    },

    enableOtp(val) {
      if (!val) {
        if (this.mobile_number) {
          setTimeout(() => {
            this.$refs.password.focus();
          }, 100);
        } else {
          this.$refs.mobile_number.focus();
        }
      } else {
        this.$refs.mobile_number.focus();
      }
    },
  },
};
</script>

<style scoped>
.login .left-panel {
  background-image: url("../assets/img/login.webp");
  background-size: cover;
  background-position: top right;
  background-repeat: no-repeat;
}
</style>
