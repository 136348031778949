<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <Navbar v-if="!$route.meta.isPublic" />
    <div v-else-if="$route.meta.isPublic" class="primary white--text py-4">
      <div
        class="bs-container d-flex justify-space-between align-center bs-gap-4"
      >
        <div v-if="$vuetify.breakpoint.lgAndUp">
          <div v-html="$t('header.social')"></div>
        </div>
        <marquee v-else>
          <div v-html="$t('header.social')"></div>
        </marquee>
        <div class="d-flex align-center bs-gap-3">
          <!-- <LanguageChanger /> -->
          <DarkModeToggle />
        </div>
      </div>
    </div>
    <Appbar v-if="$route.meta.isPublic" />

    <v-main
      :style="removeExtraPadding != false ? 'padding-top: 0 !important' : ''"
    >
      <router-view />
      <FooterGuest v-if="$route.meta.isPublic" />
      <Footer v-if="!$route.meta.isPublic" />
      <div
        v-if="!$route.meta.isPublic && $vuetify.breakpoint.smAndDown"
        class="bottom-navigation"
        :class="{ hide: show_element }"
      >
        <div class="d-flex justify-center">
          <div
            class="elevation-10 rounded-pill d-inline-flex justify-center bs-gap-2 pa-2"
            :class="$vuetify.theme.dark ? 'light' : 'dark'"
          >
            <v-btn :to="{ name: 'Account' }" icon>
              <v-icon>mdi-account</v-icon>
            </v-btn>
            <v-btn :to="{ name: 'MyBets' }" icon>
              <v-icon>mdi-clipboard-text-outline</v-icon>
            </v-btn>

            <v-btn :to="{ name: 'AddPoints' }" icon>
              <v-icon>mdi-plus-circle-multiple-outline</v-icon>
            </v-btn>

            <v-btn :to="{ name: 'Withdraw' }" icon>
              <v-icon>mdi-minus-circle-multiple-outline</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </v-main>

    <BottomNavigation
      v-if="$route.meta.isPublic && !$vuetify.breakpoint.mdAndUp"
    />
    <InternetConnectionStatus />

    <!-- Popup Error -->
    <v-dialog v-model="popUpErrorModal" persistent width="300">
      <v-card class="bs-shadow-none">
        <div class="pa-4 pa-sm-6">
          <h3 class="font-weight-bold orange--text mb-3">Warning</h3>
          <div class="grey--text mb-4 mb-sm-6">{{ popUpError }}</div>
          <div class="d-flex justify-end">
            <v-btn class="light" text @click="closePopUpErrorModal">
              Close
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- Loading Screen -->
    <v-overlay :value="overlay" opacity="1" style="z-index: 10">
      <div
        v-if="overlayContent == 'default'"
        class="rounded-lg overflow-hidden dark"
      >
        <div class="d-flex justify-center align-center bs-gap-3 pa-4 pa-sm-6">
          <v-progress-circular
            indeterminate
            :size="14"
            :width="2"
            color="primary"
          ></v-progress-circular>
          Loading
        </div>
      </div>
      <div class="rounded-lg overflow-hidden dark" v-else>
        <div class="d-flex justify-center align-center bs-gap-3 pa-4 pa-sm-6">
          <v-progress-circular
            indeterminate
            :size="14"
            :width="2"
            color="primary"
          ></v-progress-circular>
          Logging out
        </div>
      </div>
    </v-overlay>

    <v-dialog
      v-model="app_update_dialog"
      persistent
      max-width="500"
      scrollable
      style="z-index: 200"
    >
      <v-card class="elevation-0">
        <div class="pa-6 pa-md-8" style="overflow-y: auto">
          <h3 class="font-weight-bold mb-3">
            Warning: Important Update Required!
          </h3>
          <p class="grey--text">
            Attention, valued user! We have an essential update ready for your
            app.
          </p>
          <p>
            <span class="font-weight-bold">Compatibility Issues: </span>
            <span class="grey--text"
              >The current version of the app/software may encounter
              conflicts.</span
            >
          </p>
          <p class="grey--text">
            To install the update, please follow these steps:
          </p>

          <ul class="my-4">
            <li>
              Tap
              <a
                @click.prevent="downloadAPK"
                href="#"
                style="text-decoration: underline !important"
                >here</a
              >
              to download the latest version of the app
            </li>
            <li>
              Tap on it, and follow the prompts to install the latest version.
            </li>
            <li>Wait the update to finish</li>
            <li>Open and start using the app</li>
          </ul>
          <p class="grey--text">
            Thank you for your cooperation and continued support!
          </p>
          <p class="grey--text mb-0">Best Regards,</p>
          <p class="font-weight-bold mb-4">GameX Team</p>
          <v-btn @click="downloadAPK" color="primary" block depressed
            >Download Latest Version</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import Navbar from "@/components/app/Navbar";
import Appbar from "@/components/AppBarNew";
import { mapState, mapActions /* , mapGetters */ } from "vuex";
import FooterGuest from "@/components/FooterGuest";
import Footer from "@/components/Footer";
import BottomNavigation from "@/components/BottomNavigation";
import DarkModeToggle from "@/components/DarkModeToggle";
// import LanguageChanger from "@/components/LanguageChanger";
import ApiRequestGet from "@/helpers/api_request_get.js";
import InternetConnectionStatus from "@/components/InternetConnectionStatus";
import { pusherDisconnect } from "@/pusher/config";

export default {
  components: {
    Appbar,
    Navbar,
    FooterGuest,
    Footer,
    BottomNavigation,
    DarkModeToggle,
    // LanguageChanger,
    InternetConnectionStatus,
  },
  data: () => ({
    popUpErrorModal: false,
    overlay: false,
    overlayContent: "default",
    last_scroll_position: 0,
    show_element: false,
    app_update_dialog: false,
  }),
  mounted() {
    this.getMaintenanceStatus();
    this.checkBuildNumber();
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    this.$root.$on("setOverlay", (val, type = "default") => {
      this.overlay = val;
      this.overlayContent = type;
    });

    const mobile_number = localStorage.getItem("mobile_number");

    if (
      !mobile_number == "9087208311" ||
      !mobile_number == "9288176477" ||
      !mobile_number == "9175931462" ||
      !mobile_number == "9293158773" ||
      !mobile_number == "9206739051"
    ) {
      this.$store.dispatch("resetMainStates");
    }
  },
  computed: {
    ...mapState(["userData", "popUpError", "accountStatus"]), // If show error via dialog and force logout
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    currentRouteName() {
      return this.$route.name;
    },
    removeExtraPadding() {
      if (
        this.$route.name == "Home" ||
        this.$route.name == "AboutUs" ||
        this.$route.name == "ContactUs" ||
        this.$route.name == "ResponsibleGaming" ||
        this.$route.name == "About" ||
        this.$route.name == "PrivacyPolicy" ||
        this.$route.name == "TermsConditions" ||
        this.$route.name == "Maintenance"
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions("logout", ["doLogout"]),
    closePopUpErrorModal() {
      this.popUpErrorModal = false;
      this.$store.commit("setPopUpError", null);
    },
    async getMaintenanceStatus() {
      const res = await ApiRequestGet("", "maintenance");
      if (res.status == 200) {
        if (res.data.records.length > 0) {
          if (!this.allowAccessOnMaintenance()) {
            this.$store.dispatch("resetMainStates").then(() => {
              this.$router.push({
                name: "Maintenance",
              });
            });
          }
        }
      }
    },
    allowAccessOnMaintenance() {
      const mobile_number = localStorage.getItem("mobileNumber");

      // Testers
      if (
        mobile_number == "9087208311" ||
        mobile_number == "9288176477" ||
        mobile_number == "9175931462" ||
        mobile_number == "9293158773" ||
        mobile_number == "9293158781" ||
        mobile_number == "9206739051" ||
        mobile_number == "9569687108"
      )
        return true;

      return false;
    },

    async checkBuildNumber() {
      const build_version = process.env.VUE_APP_BUILD_VERSION;
      const response = await ApiRequestGet("", "mobile_app/build_version");

      if (response.status == 200) {
        if (
          response.data.build_version !== "0" &&
          build_version != response.data.build_version
        ) {
          if (this.userData) {
            pusherDisconnect(this.userData.uid);
            await this.doLogout();
            this.$router.push({ name: "Home" });
          }

          this.app_update_dialog = true;
        }
      }
    },
    downloadAPK() {
      const fileUrl =
        "https://players-api.gamexsports.com.ph/public/apk/gamex.apk";
      const link = document.createElement("a");
      link.href = fileUrl;
      link.click();
    },
    handleScroll() {
      const current_scroll_position = window.pageYOffset;

      if (current_scroll_position < this.last_scroll_position) {
        this.show_element = false;
      } else {
        this.show_element = true;
      }

      this.last_scroll_position = current_scroll_position;
    },
  },
  watch: {
    popUpError(val) {
      if (val) {
        this.popUpErrorModal = true;
      }
    },
  },
};
</script>

<style scoped>
.bottom-navigation {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  left: 1rem;
  z-index: 5;
  transition: bottom ease 0.3s !important;
}

.bottom-navigation.hide {
  bottom: calc(-56px + -1rem) !important;
}
</style>
