const url = process.env.VUE_APP_API_URL + "lucky_four"

export default {
  namespaced: true,

  state: {
    records: [],
    recordsLoading: false,
    betLoading: false,
    betError: null,
    betHasError: false,
  },

  mutations: {
    setRecords(state, val) {
      state.records = val
    },
    setRecordsLoading(state, val) {
      state.recordsLoading = val
    },
    setBetLoading(state, val) {
      state.betLoading = val
    },
    setBetError(state, val) {
      state.betError = val
    },
    setBetHasError(state, val) {
      state.betHasError = val
    },
  },

  actions: {
    async getRecords({ commit, rootState }, data) {
      commit("setRecordsLoading", true)

      try {
        const response = await fetch(`${url}?${data}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          }
        });

        const res = await response.json()

        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setRecords", res.games)
        }
      } catch (error) {
        const errorStatus = error.response.status

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true })
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true })
        }
      } finally {
        commit("setRecordsLoading", false)
      }
    },

    async doBet({ commit, rootState }, data) {
      commit("setBetLoading", true)
      commit("setBetError", null)
      commit("setBetHasError", false)

      try {
        const response = await fetch(`${url}/bet`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        });

        const res = await response.json()

        if (!response.ok) {
          throw { response, res }
        }
      } catch (error) {
        const errorStatus = error.response.status

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus == 404) {
          commit("setBetError", error.res.messages.error)
        } else {
          commit("setPopUpError", "Something went wrong, please try again!", { root: true })
        }

        commit("setBetHasError", true)
      } finally {
        commit("setBetLoading", false)
      }
    },
  }
}