import Vue from "vue";
import App from "./AppNew.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Vidle from "v-idle";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);
Vue.use(Vidle);

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: "en", // set locale
  messages: {
    en: require("../src/locales/en.json"),
    ph: require("../src/locales/ph.json"),
  }, // set locale messages
});

// NProgress
import "../node_modules/nprogress/nprogress.css";

// Global Styles
import "./assets/bootstrap.css";
import "./assets/main.css";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
