const url = process.env.VUE_APP_API_URL + "home/"

export default {
  namespaced: true,

  state: {
    counts: [],
    countsLoading: false,
    countError: null
  },

  mutations: {
    setCounts(state, val) {
      state.counts = val
    },

    setCountsLoading(state, val) {
      state.countsLoading = val
    },

    setCountError(state, val) {
        state.countError = val
    }
  },

  actions: {
    async getRecordsCount({ commit }) {
      commit("setCountsLoading", true)
      commit("setCountError", null) 

      try {
        const response = await fetch(`${url}downloads`, {
          method: 'GET',
          headers: {
            'Authorization': 'Basic cGxheWVyVW5BdXRoOkxhc2Nhc2FzR2FtaW5nQDIwMjIhQFBsYXllclVuQXV0aD09LQ==',
          }
        })

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
            commit("setCounts", res.records)
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==403) {
          commit("setCountError", error.res.messages.error) 
        } else {
          commit("setCountError", "Something went wrong, please try again!") 
          commit("setPopUpError", "Something went wrong, please try again!", { root: true })
        }
      } finally {
        commit("setCountsLoading", false)
      }
    },

    async update_android_count({ commit }) {
        commit("setCountsLoading", true)
        commit("setCountError", null) 
  
        try {
          const response = await fetch(`${url}download_android`, {
            method: 'POST',
            headers: {
              'Authorization': 'Basic cGxheWVyVW5BdXRoOkxhc2Nhc2FzR2FtaW5nQDIwMjIhQFBsYXllclVuQXV0aD09LQ==',
            }
          })
  
          const res = await response.json()
          
          if (!response.ok) {
            throw { response, res }
          } else {
            commit("setCounts", res.records)
          }
        } catch(error) {
          const errorStatus = error.response.status
          
          if (errorStatus==403) {
            commit("setCountError", error.res.messages.error) 
          } else {
            commit("setCountError", "Something went wrong, please try again!") 
            commit("setPopUpError", "Something went wrong, please try again!", { root: true })
          }
        } finally {
          commit("setCountsLoading", false)
        }
    },

    async update_ios_count({ commit }) {
        commit("setCountsLoading", true)
        commit("setCountError", null) 
  
        try {
          const response = await fetch(`${url}download_ios`, {
            method: 'POST',
            headers: {
              'Authorization': 'Basic cGxheWVyVW5BdXRoOkxhc2Nhc2FzR2FtaW5nQDIwMjIhQFBsYXllclVuQXV0aD09LQ==',
            }
          })
  
          const res = await response.json()
          
          if (!response.ok) {
            throw { response, res }
          } else {
            commit("setCounts", res.records)
          }
        } catch(error) {
          const errorStatus = error.response.status
          
          if (errorStatus==403) {
            commit("setCountError", error.res.messages.error) 
          } else {
            commit("setCountError", "Something went wrong, please try again!") 
            commit("setPopUpError", "Something went wrong, please try again!", { root: true })
          }
        } finally {
          commit("setCountsLoading", false)
        }
    },
  }
}