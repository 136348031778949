<template>
  <v-snackbar v-model="snackbar" :timeout="timeout">
    <div class="d-flex align-center bs-gap-3">
      <v-icon :color="status ? 'green' : 'grey'"> mdi-wifi </v-icon>
      {{ snackbar_message }}
    </div>
    <!-- <template v-slot:action="{ attrs }">
      <v-btn color="primary" text v-bind="attrs" @click="snackbar = false"> Okay </v-btn>
    </template> -->
  </v-snackbar>
</template>

<script>
export default {
  data: () => ({
    status: false,
    snackbar: false,
    snackbar_message: "",
    timeout: 5000,
  }),
  methods: {
    updateOnlineStatus() {
      this.status = window.navigator.onLine;

      if (this.status) {
        this.snackbar_message = "Your internet connection was restored.";
      } else {
        this.snackbar_message = "You're disconnected from the internet.";
      }

      this.snackbar = true;
    },
  },
  mounted() {
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  },
  beforeUnmount() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
};
</script>
