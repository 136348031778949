<template>
  <div>
    <div
      class="bs-position-relative d-flex align-center"
      style="width: 24px; height: 24px"
    >
      <transition name="rotate">
        <a
          href="#"
          @click.prevent="dark_mode = false"
          v-if="dark_mode"
          role="button"
          class="bs-position-absolute bs-top-0 bs-start-0"
        >
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="yellow" v-bind="attrs" v-on="on">mdi-weather-night</v-icon>
            </template>
            <span>Switch to light mode</span>
          </v-tooltip>
        </a>
      </transition>
      <transition name="rotate">
        <a
          href="#"
          @click.prevent="dark_mode = true"
          v-if="!dark_mode"
          role="button"
          class="bs-position-absolute bs-top-0 bs-start-0"
        >
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="yellow" v-bind="attrs" v-on="on"
                >mdi-white-balance-sunny</v-icon
              >
            </template>
            <span>Switch to dark mode</span>
          </v-tooltip>
        </a>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    dark_mode: false,
  }),
  watch: {
    dark_mode() {
      this.switchTheme();
    },
  },
  mounted() {
    // if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
    //   // Set default theme to dark based on the user system preference
    //   localStorage.setItem("_dm", "true");
    //   this.$vuetify.theme.dark = true;
    //   this.dark_mode = true;
    // } else {
    if (!localStorage.getItem("_dm")) {
      // Set default theme to dark mode
      localStorage.setItem("_dm", "true");
      this.$vuetify.theme.dark = true;
      this.dark_mode = true;
    } else {
      if (localStorage.getItem("_dm") == "true") {
        this.$vuetify.theme.dark = true;
        this.dark_mode = true;

        const html = document.getElementById("html");
        html.classList.remove("theme--light");
        html.classList.add("theme--dark");
      } else {
        this.$vuetify.theme.dark = false;
        this.dark_mode = false;

        const html = document.getElementById("html");
        html.classList.remove("theme--dark");
        html.classList.add("theme--light");
      }
      // }
    }
  },
  methods: {
    switchTheme() {
      if (this.dark_mode) {
        this.$vuetify.theme.dark = true;
        localStorage.setItem("_dm", true);

        const html = document.getElementById("html");
        html.classList.remove("theme--light");
        html.classList.add("theme--dark");
      } else {
        this.$vuetify.theme.dark = false;
        localStorage.setItem("_dm", false);

        const html = document.getElementById("html");
        html.classList.remove("theme--dark");
        html.classList.add("theme--light");
      }
    },
  },
};
</script>

<style scoped>
.rotate-enter-active,
.rotate-leave-active {
  transition: ease 0.3s;
}

.rotate-enter,
.rotate-leave-to {
  opacity: 0;
  transform: rotate(360deg);
}
</style>
