import Vue from "vue";
import Vuex from "vuex";
import jwtdecode from "../helpers/jwtdecode";
import downloads_count from "./home_page/downloads_count";
import ads_images from "./home_page/ads";
import home_games from "./home_page/games";
import todays_games from "./home_page/todays_games";
import winners from "./home_page/winners";
import register from "./register";
import login from "./login";
import forgot from "./forgot";
import reset_password from "./reset_password";
import account from "./account";
import ending_games from "./ending/games";
import power_ball_games from "./power_ball/games";
import ending_my_bets from "./ending/my_bets";
import add_points from "./add_points";
import withdraw from "./withdraw";
import transactions from "./transactions";
import logout from "./logout";
import my_commissions from "./my_commissions";
import lucky_four_games from "./lucky_four/games";
import lucky_four_my_bets from "./lucky_four/my_bets";
import payouts from "./payouts";

Vue.use(Vuex);

const url = process.env.VUE_APP_API_URL + "app_data";

export default new Vuex.Store({
  state: {
    userData: jwtdecode(localStorage.getItem("userToken")) || null,
    userToken: localStorage.getItem("userToken") || null,
    appData: null,
    actualPoints: null,
    accountStatus: null,
    geoLat: null,
    geoLong: null,
    error: null,
    refreshError: null,
    popUpError: null,
    appDataLoading: false,
    forPayoutToken: false,
    // userTokenChannel: false,

    // Settings ito
    pagcorMode: false,
  },
  getters: {
    isAuthenticated: (state) => !!state.userData,
    isPersonalInfoCompleted: (state) =>
      state.appData && state.appData.account_details.email_address
        ? true
        : false,
    isPicturesCompleted: (state) =>
      state.appData && state.appData.account_details.selfie ? true : false,
    isNoPicturesSubmitted: (state) =>
      state.appData && !state.appData.account_details.selfie && !state.appData.account_details.valid_id,
  },
  mutations: {
    setUserData(state, val) {
      state.userData = val;
    },
    setUserToken(state, val) {
      state.userToken = val;
    },
    setAppData(state, val) {
      state.appData = val;
    },
    setActualPoints(state, val) {
      state.actualPoints = val;
    },
    setAccountStatus(state, val) {
      state.accountStatus = val;
    },
    setGeoLat(state, val) {
      state.geoLat = val;
    },
    setGeoLong(state, val) {
      state.geoLong = val;
    },
    setError(state, val) {
      state.error = val;
    },
    setRefreshError(state, val) {
      state.refreshError = val;
    },
    setPopUpError(state, val) {
      state.popUpError = val;
    },
    setAppDataLoading(state, val) {
      state.appDataLoading = val;
    },
    setForPayoutToken(state, val) {
      state.forPayoutToken = val;
    },

    /* setUserTokenChannel (state, val) {
      state.userTokenChannel = val
    }, */
    resetStates(state) {
      state.userData = null;
      state.userToken = null;
      state.appData = null;
      state.actualPoints = null;
      state.accountStatus = null;
      (state.geoLat = null), (state.geoLong = null), (state.error = null);
      state.refreshError = null;
      state.popUpError = null;
      state.appDataLoading = false;
      // state.userTokenChannel = false
    },
  },
  actions: {
    async getAppdata({ commit, state }, data) {
      commit("setAppDataLoading", true);

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${state.userToken}`,
          },
          body: data,
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setAppData", res);
          commit("setActualPoints", res.account_details.actual_points);
          commit("setAccountStatus", res.account_details.account_verified);
          commit("setForPayoutToken", res.forPayout);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        if (errorStatus == 401) {
          commit("setError", "Unauthorized");
        } else {
          commit("setSlotsError", "Something went wrong");
          commit("setPopUpError", "Something went wrong");
        }
      } finally {
        commit("setAppDataLoading", false);
      }
    },

    async resetMainStates({ commit }) {
      commit("resetStates", "");
      commit("setUserData", null);
      commit("setUserToken", {});
      localStorage.removeItem("userToken");
    },

    /* async getBanks({ commit, state }) {
      try {
        const response = await fetch(`${url}/banks`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${state.userToken}`,
          }
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setBanks", res.banks)
        }
      } catch(error) {
        // nothing
      }
    } */
  },
  modules: {
    downloads_count,
    ads_images,
    home_games,
    todays_games,
    winners,
    register,
    login,
    forgot,
    reset_password,
    account,
    ending_games,
    power_ball_games,
    ending_my_bets,
    add_points,
    withdraw,
    transactions,
    logout,
    my_commissions,
    lucky_four_games,
    lucky_four_my_bets,
    payouts,
  },
});
