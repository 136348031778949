<template>
  <footer class="footer dark pa-4 pa-sm-6 pa-md-8">
    <div class="bs-row bs-g-3 bs-g-lg-5">
      <div class="bs-col-lg-5">
        <div class="mb-4 md-sm-6">
          <img
            v-if="$vuetify.theme.dark"
            src="@/assets/GAMEX LOGO FINAL 3-02.png"
            alt="GameX Sports"
            class="footer-logo"
          />

          <img
            v-else
            src="@/assets/GAMEX LOGO FINAL-02.png"
            alt="GameX Sports"
            class="footer-logo"
          />
        </div>
        <ul>
          <li>
            <a
              href="https://goo.gl/maps/Bi7E4RghoMJmDGSu9"
              target="_blank"
              rel="noopener noreferrer"
            >
              Victoria Sports Tower EDSA, Kamuning Brgy, South Triangle, Quezon City
            </a>
          </li>
          <li>
            <a href="mailto:support@gamexsports.com.ph">support@gamexsports.com.ph</a>
          </li>
          <li>
            <a href="tel:+639175983522">+639175983522</a>
          </li>
        </ul>
        <div class="d-flex mt-4 mt-sm-6">
          <div class="d-flex bs-gap-3">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <img
                    v-if="$vuetify.theme.dark"
                    src="@/assets/keep-it-fun.png"
                    alt="21+"
                    style="width: auto; height: 56px"
                    class="twenty-one-plus-img d-inline-block"
                  />
                  <img
                    v-else
                    src="@/assets/keep-it-fun-dark.png"
                    alt="21+"
                    style="width: auto; height: 56px"
                    class="twenty-one-plus-img d-inline-block"
                  />
                </div>
              </template>
              <span>Gaming for 21 years old & above only</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <img
                    v-if="$vuetify.theme.dark"
                    src="@/assets/pagcor-logo-dark.webp"
                    style="width: auto; height: 56px"
                    class="pagcor-img d-inline-block"
                  />
                  <img
                    v-else
                    src="@/assets/pagcor-logo-light.webp"
                    style="width: auto; height: 56px"
                    class="pagcor-img d-inline-block"
                  />
                </div>
              </template>
              <span>Licensed by PAGCOR</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <img
                    src="@/assets/lcg-logo.webp"
                    style="width: auto; height: 56px"
                    class="lcg-img d-inline-block"
                  />
                </div>
              </template>
              <span>Las Casas Gaming, Inc.</span>
            </v-tooltip>
          </div>
        </div>
      </div>
      <div class="bs-col-lg-4">
        <div class="font-weight-bold mb-2 mb-sm-6">Company</div>
        <ul class="mb-0">
          <li>
            <a href="#" @click.prevent="about_us_dialog = true"> About Us </a>
          </li>
          <li>
            <a href="#" @click.prevent="contact_us_dialog = true"> Contact Us </a>
          </li>
          <li>
            <a href="#" @click.prevent="responsible_gaming_dialog = true">
              Responsible Gaming
            </a>
          </li>
        </ul>
      </div>
      <div class="bs-col-lg-3">
        <div class="font-weight-bold mb-3 mb-sm-6">Payment</div>
        <div class="d-flex bs-gap-2 mb-4 mb-sm-6 mb-md-12">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <img
                  src="@/assets/img/payments/gcash-logo.webp"
                  style="display: block !important; width: auto; height: 45px"
                  class="gcash-img d-block rounded-circle"
                />
              </div>
            </template>
            <span>GCash</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <img
                  src="@/assets/img/payments/maya-logo.webp"
                  style="display: block !important; width: auto; height: 45px"
                  class="maya-img d-block rounded-circle"
                />
              </div>
            </template>
            <span>Paymaya</span>
          </v-tooltip>
        </div>
        <div class="font-weight-bold mb-3 mb-sm-6">Social</div>
        <div class="d-flex bs-gap-2" style="flex-wrap: wrap !important">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn
                  @click="
                    redirect('https://www.facebook.com/profile.php?id=100082248056340')
                  "
                  icon
                  style="background-color: #0571ed"
                  dark
                  large
                >
                  <i class="fab fa-facebook-f fa-lg"></i>
                </v-btn>
              </div>
            </template>
            <span>Facebook</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn
                  @click="
                    redirect(
                      'https://invite.viber.com/?g2=AQBh5hrwB44bOFB8i4fkgHvokV4NbBiRPfWCIacRItskBuHq5RoSzN18x%2BjXl8np'
                    )
                  "
                  icon
                  style="background-color: #795bef"
                  dark
                  large
                >
                  <i class="fab fa-viber fa-lg"></i>
                </v-btn>
              </div>
            </template>
            <span>Viber</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn
                  @click="redirect('https://www.instagram.com/gamexsportsph/')"
                  icon
                  style="background-color: #e23c67"
                  dark
                  large
                >
                  <i class="fab fa-instagram fa-lg"></i>
                </v-btn>
              </div>
            </template>
            <span>Instagram</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn
                  @click="
                    redirect('https://www.youtube.com/channel/UC5Dw0Emv4VfsMmmqx2PU_2A')
                  "
                  icon
                  style="background-color: #f70000"
                  dark
                  large
                >
                  <i class="fab fa-youtube fa-lg"></i>
                </v-btn>
              </div>
            </template>
            <span>YouTube</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn
                  @click="redirect('https://www.linkedin.com/in/gamex-sports-b11008264/')"
                  icon
                  style="background-color: #0270ad"
                  dark
                  large
                >
                  <i class="fab fa-linkedin fa-lg"></i>
                </v-btn>
              </div>
            </template>
            <span>Linkedin</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn
                  @click="
                    redirect(
                      'https://www.tiktok.com/@gamexsports?_t=8ZOf83ldqcc&_r=1&fbclid=IwAR2EKVS-VTbvPjxIdAfT5cA7Hec38B1Hu2NicV8uRVpvULGwI2p2EgW0T-w'
                    )
                  "
                  icon
                  style="background-color: #f0f0f0"
                  dark
                  large
                >
                  <i class="fab fa-tiktok fa-lg" style="filter: invert(1)"></i>
                </v-btn>
              </div>
            </template>
            <span>TikTok</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn
                  @click="redirect('https://twitter.com/GamexSports')"
                  icon
                  style="background-color: #f0f0f0"
                  dark
                  large
                >
                  <img
                    src="https://cdn.cms-twdigitalassets.com/content/dam/about-twitter/x/brand-toolkit/logo-black.png.twimg.2560.png"
                    alt="X Logo"
                    style="width: auto; height: 18px"
                  />
                </v-btn>
              </div>
            </template>
            <span>Twitter</span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <div class="pt-4 pt-sm-6 pt-md-12">
      <div class="d-flex flex-column bs-gap-2 bs-gap-md-3">
        <span>© {{ new Date().getFullYear() }} GameX Sports </span>
        <div class="d-flex bs-gap-4">
          <a href="#" @click.prevent="privacy_policy_dialog = true"> Privacy Policy </a>
          <a href="#" @click.prevent="terms_and_conditions_dialog = true">
            Terms of Use
          </a>
        </div>
      </div>
    </div>

    <!-- About Us Dialog -->
    <v-dialog v-model="about_us_dialog" max-width="500" scrollable>
      <v-card class="bs-shadow-none">
        <div class="pa-4 pa-sm-6 pa-md-8" style="overflow-y: auto">
          <v-btn class="float-end" small icon @click="about_us_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <AboutUs />
        </div>
      </v-card>
    </v-dialog>

    <!-- Contact Us Dialog -->
    <v-dialog v-model="contact_us_dialog" max-width="500" scrollable>
      <v-card class="bs-shadow-none">
        <div class="pa-4 pa-sm-6 pa-md-8" style="overflow-y: auto">
          <v-btn class="float-end" small icon @click="contact_us_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <ContactUs />
        </div>
      </v-card>
    </v-dialog>

    <!-- Responsible Gaming Dialog -->
    <v-dialog v-model="responsible_gaming_dialog" max-width="600" scrollable>
      <v-card class="responsible-gaming-card bs-shadow-none">
        <div class="pa-4 pa-sm-6 pa-md-8" style="overflow-y: auto">
          <v-btn
            @click="responsible_gaming_dialog = false"
            icon
            small
            absolute
            dark
            style="top: 1.5rem; right: 1.5rem"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <ResponsibleGaming class="mt-16 mt-sm-0" />
        </div>
      </v-card>
    </v-dialog>

    <!-- Privacy Policy Dialog -->
    <v-dialog v-model="privacy_policy_dialog" max-width="500" scrollable>
      <v-card class="bs-shadow-none">
        <div class="pa-4 pa-sm-6 pa-md-8" style="overflow-y: auto">
          <v-btn class="float-end" small icon @click="privacy_policy_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <PrivacyPolicy />
        </div>
      </v-card>
    </v-dialog>

    <!-- Terms and Conditions Dialog -->
    <v-dialog v-model="terms_and_conditions_dialog" max-width="500" scrollable>
      <v-card class="bs-shadow-none">
        <div class="pa-4 pa-sm-6 pa-md-8" style="overflow-y: auto">
          <v-btn
            class="float-end"
            small
            icon
            @click="terms_and_conditions_dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <TermsConditions />
        </div>
      </v-card>
    </v-dialog>
  </footer>
</template>

<script>
import AboutUs from "@/components/AboutUs.vue";
import ContactUs from "@/components/ContactUs.vue";
import ResponsibleGaming from "@/components/ResponsibleGaming.vue";
import PrivacyPolicy from "@/components/PrivacyPolicy.vue";
import TermsConditions from "@/components/TermsConditions.vue";

export default {
  data: () => ({
    about_us_dialog: false,
    contact_us_dialog: false,
    responsible_gaming_dialog: false,
    privacy_policy_dialog: false,
    terms_and_conditions_dialog: false,
  }),
  components: {
    AboutUs,
    ContactUs,
    ResponsibleGaming,
    PrivacyPolicy,
    TermsConditions,
  },
  methods: {
    redirect(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style scoped>
.responsible-gaming-card {
  color: #fff !important;
  background: rgb(0, 115, 211) !important;
  background: radial-gradient(
    circle,
    rgba(0, 115, 211, 1) 0%,
    rgba(1, 105, 190, 1) 100%
  ) !important;
}
@media (max-width: 768px) {
  .twenty-one-plus-img,
  .lcg-img {
    height: 20px;
  }
}
</style>
