const url = process.env.VUE_APP_API_URL

export default {
  namespaced: true,

  state: {
    records: [],
    recordsLoading: false,
    bankAccount: null,
    bankAccountLoading: false,
    addPointsError: null,
    addPointsLoading: false,
    paymayaError: null,
    paymayaLoading: false,
    gcashError: null,
    gcashLoading: false,
    masterAgentType: null,
    masterAgentTypeLoading: false,
    cashinAmounts: []
  },
  mutations: {
    setRecords (state, val) {
      state.records = val
    },
    setRecordsLoading (state, val) {
      state.recordsLoading = val
    },
    setBankAccount(state, val) {
      state.bankAccount = val
    },
    setBankAccountLoading(state, val) {
      state.bankAccountLoading = val
    },
    setAddPointsError(state, val) {
      state.addPointsError = val
    },
    setAddPointsLoading(state, val) {
      state.addPointsLoading = val
    },
    setPaymayaError(state, val) {
      state.paymayaError = val
    },
    setPaymayaLoading(state, val) {
      state.paymayaLoading = val
    },
    setGcashError(state, val) {
      state.gcashError = val
    },
    setGcashLoading(state, val) {
      state.gcashLoading = val
    },
    setMasterAgentType(state, val) {
      state.masterAgentType = val
    },
    setMasterAgentTypeLoading(state, val) {
      state.masterAgentTypeLoading = val
    },
    setCashinAmounts(state, val) {
      state.cashinAmounts = val
    }
  },

  actions: {
    async getRecords({ commit, rootState }, data) {
      commit("setRecordsLoading", true)

      try {
        const response = await fetch(`${url}cashin/history?${data}` , {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          }
        })

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setRecords", res.records)
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true })
        }
      } finally {
        commit("setRecordsLoading", false)
      }
    },

    async doGetBankAccount({ commit, rootState }, data) {
      commit("setBankAccountLoading", true)
      
      try {
        const response = await fetch(`${url}account/bank_account?${data}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          }
        })

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setBankAccount", res)
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else {
          commit("setPopUpError", "Something went wrong, please try again!", { root: true })
        }
      } finally {
        commit("setBankAccountLoading", false)
      }
    },

    async doSubmit({ commit, rootState }, data) {
      commit("setAddPointsLoading", true)
      commit("setAddPointsError", null) 

      try {
        const response = await fetch(`${url}cashin`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        })

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          return res
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else {
          commit("setAddPointsError", "Something went wrong, please try again!") 
          commit("setPopUpError", "Something went wrong, please try again!", { root: true })
        }
      } finally {
        commit("setAddPointsLoading", false)
      }
    },

    async submitPaymaya({ commit, rootState }, data) {
      commit("setPaymayaLoading", true)
      commit("setPaymayaError", null) 

      try {
        const response = await fetch(`${url}cashin/paymaya`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        })

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          return res
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus==403) {
          commit("setPaymayaError", error.res.messages.error) 
        } else {
          commit("setPaymayaError", "Something went wrong.") // Dummy error
          commit("setPopUpError", "Something went wrong, please try again!", { root: true })
        }
      } finally {
        commit("setPaymayaLoading", false)
      }
    },

    async submitGcash({ commit, rootState }, data) {
      commit("setGcashLoading", true)
      commit("setGcashError", null) 

      try {
        const response = await fetch(`${url}cashin/gcash`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        })

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          return res
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus==403) {
          commit("setGcashError", error.res.messages.error) 
        } else {
          commit("setGcashError", "Something went wrong.") // Dummy error
          commit("setPopUpError", "Something went wrong, please try again!", { root: true })
        }
      } finally {
        commit("setGcashLoading", false)
      }
    },

    async doGetMasterAgentType({ commit, rootState }) {
      commit("setMasterAgentTypeLoading", true)

      try {
        const response = await fetch(`${url}cashin/master_agent_type` , {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          }
        })

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setMasterAgentType", res.record)
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true })
        }
      } finally {
        commit("setMasterAgentTypeLoading", false)
      }
    },

    async getCashinAmounts({ commit, rootState }) {
      try {
        const response = await fetch(`${url}cashin/amounts` , {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          }
        })

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setCashinAmounts", res)
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true })
        }
      }
    }
  }
}