<template>
  <v-list class="dark py-0" style="background: transparent !important">
    <div class="font-weight-bold px-4 px-sm-6 mb-3 mt-4 mt-sm-6">Games</div>

    <v-list-item
      link
      router
      :to="{ name: 'EndingGames' }"
      color="primary"
      class="px-4 px-sm-6"
    >
      <v-list-item-icon>
        <v-icon>mdi-basketball</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>Basketball Ending</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      link
      router
      :to="{ name: 'LuckyFourGames' }"
      color="primary"
      class="px-4 px-sm-6"
    >
      <v-list-item-icon>
        <v-icon>mdi-basketball</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>Lucky Four</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      v-if="showPowerBall"
      link
      router
      :to="{ name: 'PowerBallHome' }"
      color="primary"
      class="px-4 px-sm-6"
    >
      <v-list-item-icon>
        <v-icon>mdi-basketball</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>Power Ball</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      link
      router
      :to="{ name: 'MyBets' }"
      color="primary"
      class="px-4 px-sm-6"
    >
      <v-list-item-icon>
        <v-icon>mdi-clipboard-text-outline</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>Transactions</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      link
      router
      :to="{ name: 'NewsAndUpdates' }"
      color="primary"
      class="px-4 px-sm-6"
    >
      <v-list-item-icon>
        <v-icon>mdi-bullhorn-variant-outline</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>News and Updates</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <div v-if="isSalesRep">
      <div class="font-weight-bold px-4 px-sm-6 mb-3 mt-4 mt-sm-6">Agent</div>

      <v-list-item
        link
        router
        :to="{ name: 'MyCommissions' }"
        color="primary"
        class="px-4 px-sm-6"
      >
        <v-list-item-icon>
          <v-icon>mdi-account-cash-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>My Commissions</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        link
        router
        :to="{ name: 'Payouts' }"
        color="primary"
        class="px-4 px-sm-6"
      >
        <v-list-item-icon>
          <v-icon>mdi-account-cash-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>My Payouts</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>

    <!-- <v-subheader class="bs-text-dark bs-fw-bold"> Power Ball </v-subheader>

    <v-list-item
      
      link
      router
      v-for="(power_ball, index) in power_balls"
      :key="index"
      :to="{ name: power_ball.route }"
      color="primary"
    >
      <v-list-item-icon>
        <v-icon>{{ power_ball.icon }}</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ power_ball.title }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item> -->

    <!-- <div v-if="restrictLuckyFour">
      <div class="font-weight-bold px-4 px-sm-6 mb-3 mt-4 mt-sm-6">
        Lucky Four
      </div>

      <v-list-item
        link
        router
        :to="{ name: 'LuckyFourGames' }"
        color="primary"
        class="px-4 px-sm-6"
      >
        <v-list-item-icon>
          <v-icon>mdi-basketball</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Games</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        link
        router
        :to="{ name: 'LuckyFourMyBets' }"
        color="primary"
        class="px-4 px-sm-6"
      >
        <v-list-item-icon>
          <v-icon>mdi-clipboard-text-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Transactions</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div> -->

    <div v-if="!isDisableAddToken">
      <div class="font-weight-bold px-4 px-sm-6 mb-3 mt-4 mt-sm-6">Wallet</div>

      <v-list-item
        link
        router
        :to="{ name: 'AddPoints' }"
        color="primary"
        class="px-4 px-sm-6"
      >
        <v-list-item-icon>
          <v-icon>mdi-plus-circle-multiple-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Add Token</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        link
        router
        :to="{ name: 'Withdraw' }"
        color="primary"
        class="px-4 px-sm-6"
      >
        <v-list-item-icon>
          <v-icon>mdi-minus-circle-multiple-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Withdraw</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- <v-list-item
        link
        router
        :to="{ name: 'Transactions' }"
        color="primary"
        class="px-4 px-sm-6"
      >
        <v-list-item-icon>
          <v-icon>mdi-clipboard-text-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Transactions</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
    </div>
  </v-list>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    agent_id: {
      type: String,
    },
  },

  computed: {
    ...mapState(["appData", "userData"]),

    isDisableAddToken() {
      if (this.appData) {
        if (
          this.appData.account_details.agent_sub_type_id == 1 ||
          (this.appData.account_details.investor_sub_type_id == 1 &&
            this.appData.account_details.investor_type_id != 1)
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    isSalesRep() {
      if (
        this.appData &&
        this.appData.account_details.investor_sub_agent_player_id &&
        this.appData.account_details.investor_sub_type_id == 1
      ) {
        return true;
      } else {
        return false;
      }
    },
    showPowerBall() {
      const allowedNumbers = [
        // "9983826290",
        // "9691830078",
        // "9480150332",
        // "9691957546",
        // "9206739051",
        // "9175931461",
        // "9112233442",
        "9569687108",
        // "9288176476",
      ];

      if (allowedNumbers.includes(this.userData.mobile_number)) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    logout() {
      this.$emit("logout");
    },
  },
};
</script>
