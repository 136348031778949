<template>
  <v-dialog v-model="dialog" max-width="400">
    <v-card class="bs-shadow-none pa-0">
      <div class="pa-6 pa-md-10">
        <v-btn icon @click="setDialogs('forgot')" class="float-end">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div class="mb-3">
          <v-img
            v-if="$vuetify.theme.dark"
            class="d-inline-block"
            src="@/assets/GAMEX LOGO FINAL 3-02.png"
            :max-width="$vuetify.breakpoint.mdAndUp ? 150 : 120"
            contain
            alt="GameX Sports"
          ></v-img>
          <v-img
            v-else
            class="d-inline-block"
            src="@/assets/GAMEX LOGO FINAL-02.png"
            :max-width="$vuetify.breakpoint.mdAndUp ? 150 : 120"
            contain
            alt="GameX Sports"
          ></v-img>
        </div>
        <div class="mb-4 mb-md-8">
          Forgot your password? Enter your email address or mobile number and we'll send
          you instructions to reset your password.
        </div>
        <v-alert v-model="successAlert" class="mb-4" type="success" text>
          {{
            "Successfully Sent! We have sent your reset password link to your " +
            successUsed
          }}.
        </v-alert>
        <!-- <p class="mt-1 caption font-italic">
                Example of mobile number format '+639293158123' or '09293158123'
              </p> -->
        <v-form ref="form" @submit.prevent="handleSubmit">
          <v-text-field
            ref="forgot_val"
            v-model="forgot_val"
            :rules="[rules.required, forgotValError]"
            label="Email Address or Mobile Number"
            outlined
            hide-details="auto"
            dense
          ></v-text-field>
          <v-btn
            depressed
            block
            class="justify-space-between mt-4 mt-sm-6"
            color="primary"
            type="submit"
            :loading="loading"
          >
            Send Reset Link
            <v-icon> mdi-arrow-right-thin</v-icon>
          </v-btn>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import formRules from "@/helpers/formRules";

export default {
  data: () => ({
    rules: formRules,
    forgot_val: "",
    forgotValErrorText: "",
    forgotValErrorDesc: "",
    successAlert: false,
    successUsed: "",
  }),
  computed: {
    ...mapState("forgot", ["error", "accountError", "loading"]),
    dialog: {
      get: function () {
        return true;
      },
      set: function () {
        this.setDialogs("forgot");
      },
    },
  },
  mounted() {
    this.$refs.forgot_val.focus();
  },
  methods: {
    ...mapActions("forgot", ["doForgot"]),
    setDialogs(selectedDialog) {
      this.$emit("setDialogs", selectedDialog);
    },
    forgotValError(val) {
      if (val == this.forgotValErrorText) {
        return this.forgotValErrorDesc;
      } else {
        return true;
      }
    },
    async handleSubmit() {
      this.forgotValErrorText = "";
      this.forgotValErrorDesc = "";

      if (this.$refs.form.validate()) {
        if (!this.loading) {
          const formData = new FormData();
          formData.append("forgot_val", this.forgot_val);

          const res = await this.doForgot(formData);

          if (!this.error && !this.accountError) {
            this.successAlert = true;
            this.successUsed = res.type;
            this.$refs.form.reset();
          } else {
            if (this.accountError) {
              this.forgotValErrorText = this.forgot_val;
              this.forgotValErrorDesc = this.accountError;
              this.$refs.forgot_val.validate();
              this.$refs.forgot_val.focus();
            }
          }
        }
      }
    },
  },
};
</script>
