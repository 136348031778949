const url = process.env.VUE_APP_API_URL + "home/"

export default {
  namespaced: true,

  state: {
    records: [],
    error: null,
    recordsLoading: false
  },

  mutations: {
    setRecords(state, val) {
      state.records = val
    },

    setError(state, val) {
      state.error = val
    },
    setRecordsLoading(state, val) {
      state.recordsLoading = val
    },
  },

  actions: {
    async getRecords({ commit }) {
      commit("setRecordsLoading", true)
      commit("setError", null) 

      try {
        const response = await fetch(`${url}winners`, {
          method: 'GET',
          headers: {
            'Authorization': 'Basic cGxheWVyVW5BdXRoOkxhc2Nhc2FzR2FtaW5nQDIwMjIhQFBsYXllclVuQXV0aD09LQ==',
          }
        })

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
            commit("setRecords", res)
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==403) {
          commit("setAccountError", error.res.messages.error) 
        } else {
          commit("setError", "Something went wrong, please try again!") 
          commit("setPopUpError", "Something went wrong, please try again!", { root: true })
        }
      } finally {
        commit("setRecordsLoading", false)
      }
    },
  }
}